import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { desktop } from "./breakpoints";
import { rem } from "./utils";
import { border1pxGray, grayBGLight, white } from "../styles/colors";

import gridBGImg from "../images/bg-grid.jpg";
import gridBGImgInvert from "../images/bg-grid-inverted.jpg";

export * from "./text-styles";

export const block = css`
  display: block;
`;

export const absolute = css`
  position: absolute;
`;

export const relative = css`
  position: relative;
`;

export const bgWhite = css`
  background-color: white;
`;

export const bgOffWhite = css`
  background-color: var(--off-white);
`;

export const textWhite = css`
  color: #fff;
`;

export const cover = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const top1of2 = css`
  top: 50%;
`;

export const left1of2 = css`
  left: 50%;
`;

export const baseList = css`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const baseBtn = css`
  border: 0;
  background: transparent;
  color: inherit;
  cursor: pointer;
`;

export const bgBlack = css`
  background-color: black;
`;

export const clipBox = css`
  overflow: hidden;
`;

export const inline = css`
  display: inline;
`;

export const mobileGutter = css`
  padding-left: ${rem(30)};
  padding-right: ${rem(30)};
`;

export const borderTop = css`
  border-top: 1px solid var(--border-color);
`;

export const borderBot = css`
  border-bottom: 1px solid var(--border-color);
`;

export const borderRound = css`
  border-radius: 9999px;
`;

export const containerNarrow = css`
  --max-width: 1090px;
`;

export const containerNarrower = css`
  --max-width: 880px;
`;

export const container = css`
  max-width: var(--max-width);
  ${mobileGutter};
  margin: auto;
`;

export const w100 = css`
  width: 100%;
`;

export const w1of2Md = css`
  @media (min-width: 600px) {
    width: 50%;
  }
`;

export const maxWidth500px = css`
  max-width: 500px;
`;

export const maxWidth800px = css`
  max-width: 800px;
`;

export const maxWidth950px = css`
  max-width: 950px;
`;

export const mxauto = css`
  margin-left: auto;
  margin-right: auto;
`;

export const my0 = css`
  margin-top: 0;
  margin-bottom: 0;
`;

export const mt8 = css`
  margin-top: 2rem;
`;

export const mt10 = css`
  margin-top: 2.5rem;
`;

export const mt16 = css`
  margin-top: 4rem;
`;

export const mb1 = css`
  margin-bottom: 0.25rem;
`;

export const mb2 = css`
  margin-bottom: 0.5rem;
`;

export const mb3 = css`
  margin-bottom: 0.75rem;
`;

export const mb4 = css`
  margin-bottom: 1rem;
`;

export const mb6 = css`
  margin-bottom: 1.5rem;
`;

export const mb8 = css`
  margin-bottom: 2rem;
`;

export const mb16 = css`
  margin-bottom: 4rem;
`;

export const p0 = css`
  padding: 0;
`;

export const py8 = css`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

export const py12 = css`
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

export const py15 = css`
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
`;

export const py20 = css`
  padding-top: 4rem;
  padding-bottom: 4rem;
`;

export const pt26 = css`
  padding-top: 6.5rem;
`;

export const pb8 = css`
  padding-bottom: 2rem;
`;

export const pb28 = css`
  padding-bottom: 7rem;
`;

export const pb36 = css`
  padding-bottom: 9rem;
`;

export const pb72 = css`
  padding-bottom: 18rem;
`;

export const z1 = css`
  z-index: 1;
`;

export const z5 = css`
  z-index: 5;
`;

export const Container = styled.div`
  ${container};
`;

export const flex = css`
  display: flex;
`;

export const flexRowMd = css`
  @media (min-width: 600px) {
    flex-direction: row;
  }
`;

export const flexRowReverseMd = css`
  @media (min-width: 600px) {
    flex-direction: row-reverse;
  }
`;

export const flexCol = css`
  flex-direction: column;
`;

export const justifyStart = css`
  justify-content: flex-start;
`;

export const justifyEnd = css`
  justify-content: flex-end;
`;

export const justifyCenter = css`
  justify-content: center;
`;

export const justifyBetweenMd = css`
  @media (min-width: 600px) {
    justify-content: space-between;
  }
`;

export const itemsStart = css`
  align-items: flex-start;
`;

export const itemsEnd = css`
  align-items: flex-end;
`;

export const itemsCenter = css`
  align-items: center;
`;

export const flexColRev = css`
  display: flex;
  flex-direction: column-reverse;
`;

export const flexCenterAll = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const flexColCenterAll = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const visuallyHidden = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
`;

export const SRTxt = styled.span`
  ${visuallyHidden};
`;

export const gridBG = css`
  background-size: 40px 40px;
  background-image: linear-gradient(
      to right,
      ${border1pxGray} 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, ${border1pxGray} 1px, transparent 1px);

  @media (min-device-pixel-ratio: 2) {
    background-image: linear-gradient(
        to right,
        ${grayBGLight} 0.5px,
        transparent 0.5px
      ),
      linear-gradient(to bottom, ${grayBGLight} 0.5px, transparent 0.5px);
  }

  @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background: 2px 2px url(${gridBGImg});
  }
`;

export const gridBGInvert = css`
  background-color: ${grayBGLight};
  background-size: 40px 40px;
  background-image: linear-gradient(
      to right,
      ${border1pxGray} 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, ${border1pxGray} 1px, transparent 1px);

  @media (min-device-pixel-ratio: 2) {
    background-image: linear-gradient(
        to right,
        ${white} 0.5px,
        transparent 0.5px
      ),
      linear-gradient(to bottom, ${white} 0.5px, transparent 0.5px);
  }

  @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background: 2px 2px url(${gridBGImgInvert});
  }
`;

export const gridPadTop = css`
  padding-top: ${rem(45)};

  @media (${desktop}) {
    padding-top: ${rem(80)};
  }
`;

export const Grid = styled.div`
  ${({ invert }) => (!!invert ? gridBGInvert : gridBG)};
  ${({ padTop }) => !!padTop && gridPadTop}
`;

export const sectionSpacing = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${rem(150)};
  padding-bottom: ${rem(100)};
`;

export const dropShadow = css`
  position: absolute;
  right: -80px;
  top: 0;
  bottom: 0;
  height: 100vh;
  background-color: transparent;
  z-index: 10;
  width: 80px;
  box-shadow: -10px 4px 25px rgba(0, 0, 0, 0.25);
`;
